import React from 'react';

const Cancel = () => {
  return (
    <div className="cancel-page">
      <div className="logo">
        <img src="ML_Logo_Hor_White.png" alt="ManifestLab Logo" className="logo-img" />
      </div>
      <h1>Payment Cancelled</h1>
      <p>Your payment was not completed. Please try again or contact support if you have any questions.</p>
    </div>
  );
};

export default Cancel;