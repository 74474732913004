import React, { createContext, useContext, useState, useCallback } from 'react';

// Create the context
const NavigationContext = createContext();

// Create a provider component
export const NavigationProvider = ({ children }) => {
  const [disableUnload, setDisableUnload] = useState(false);

  // Function to enable the confirmation popup
  const enableConfirmation = useCallback(() => {
    setDisableUnload(false);
  }, []);

  // Function to disable the confirmation popup
  const disableConfirmation = useCallback(() => {
    setDisableUnload(true);
  }, []);

  return (
    <NavigationContext.Provider
      value={{ disableUnload, enableConfirmation, disableConfirmation }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

// Custom hook for easy access
export const useNavigation = () => {
  return useContext(NavigationContext);
};