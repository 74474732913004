import React from 'react';

const Terms = () => {
  const termsHTML = `
    <h2>Terms & Conditions</h2>
    <h3>1. Introduction</h3>
    <p>Welcome to ManifestLab! These Terms and Conditions ("Terms", "Terms and Conditions") govern your use of our website and services (the "Service") operated by ManifestLab ("we," "us," or "our"). By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the Service.</p>
    <h3>2. Use of Our Service</h3>
    <ul>
        <li><strong>Eligibility:</strong> You must be at least 18 years old to use our Service. By using our Service, you represent and warrant that you are at least 18 years old.</li>
        <li><strong>Service Access:</strong> Currently, users do not create accounts to access our Service. You may use our Service to generate personalized manifestation meditations based on the goals you provide.</li>
    </ul>

    <h3>3. Content</h3>
    <ul>
        <li><strong>User Goals:</strong> Our Service collects information about your personal goals to create customized manifestation meditations. This information is used solely for the purpose of generating your personalized content and is not shared with third parties except as outlined in our Privacy Policy.</li>
        <li><strong>Prohibited Use:</strong> You agree not to use our Service for any unlawful or prohibited purpose. You agree not to use our Service in any manner that could damage, disable, overburden, or impair our Service or interfere with any other party's use and enjoyment of our Service.</li>
    </ul>

    <h3>4. Intellectual Property</h3>
    <ul>
        <li><strong>Our Content:</strong> The Service and its original content, features, and functionality are and will remain the exclusive property of ManifestLab and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.</li>
        <li><strong>License:</strong> We grant you a limited, non-exclusive, non-transferable, and revocable license to use our Service for your personal, non-commercial use, subject to these Terms.</li>
    </ul>

    <h3>5. Payments</h3>
    <ul>
        <li><strong>Payment Terms:</strong> Our Service requires a one-time payment to access personalized manifestation meditations. The fee is charged at the time of purchase.</li>
        <li><strong>Fee Changes:</strong> We may modify the fees at our sole discretion. Any fee changes will not affect purchases that have already been completed.</li>
        <li><strong>Refunds:</strong> Except when required by law, fees paid for our Service are non-refundable.</li>
    </ul>

    <h3>6. Termination</h3>
    <ul>
        <li><strong>Termination by You:</strong> You may terminate your use of the Service at any time. If you terminate your use of the Service, you will not be entitled to any refunds of any fees paid.</li>
        <li><strong>Termination by Us:</strong> We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.</li>
    </ul>

    <h3>7. Limitation of Liability</h3>
    <p>In no event shall ManifestLab, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.</p>

    <h3>8. Governing Law</h3>
    <p>These Terms shall be governed and construed in accordance with the laws of Australia, without regard to its conflict of law provisions.</p>

    <h3>9. Changes to Terms and Conditions</h3>
    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days'' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

    <h3>10. Contact Us</h3>
    <p>If you have any questions about these Terms, please contact us at <a href="mailto:contact@manifestlab.io">contact@manifestlab.io</a>.</p>
  `;

  return (
    <div style={{ margin: '20px' }} dangerouslySetInnerHTML={{ __html: termsHTML }} />
  );
};

export default Terms;