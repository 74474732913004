import React from 'react';

const Disclaimer = () => {
  const disclaimerHTML = `
    <h2>Disclaimer</h2>
    <h3>General Disclaimer:</h3>
    <p>The information provided by ManifestLab ("we," "us," or "our") on our website and through our services is for general informational purposes only. All information on the website and through our services is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the website or through our services.</p>

    <p>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the website or our services or reliance on any information provided on the website and through our services. Your use of the website and our services and your reliance on any information on the website and through our services is solely at your own risk.</p>

    <h3>No Guarantees of Results:</h3>
    <p>ManifestLab aims to provide tools and resources to help users achieve their personal goals. However, individual results may vary and are dependent on numerous factors including, but not limited to, individual effort, commitment, and circumstances. We cannot and do not guarantee specific results from using our services. Any testimonials or examples of results are not intended to represent or guarantee that you will achieve the same or similar results.</p>

    <h3>Medical Disclaimer:</h3>
    <p>The content provided by ManifestLab is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on our website or through our services.</p>

    <p>ManifestLab does not offer medical advice, and nothing contained in our content, products, or services should be construed as medical advice. The information and statements regarding our products and services have not been evaluated by the Food and Drug Administration and are not intended to diagnose, treat, cure, or prevent any disease.</p>

    <p>If you have any concerns about your health or the effectiveness of our services, you should consult with a healthcare professional. Reliance on any information provided by ManifestLab is solely at your own risk.</p>

    <h3>Contact Us</h3>
    <p>If you have any questions about this disclaimer, please contact us at <a href="mailto:contact@manifestlab.io">contact@manifestlab.io</a>.</p>
  `;

  return (
    <div style={{ margin: '20px' }} dangerouslySetInnerHTML={{ __html: disclaimerHTML }} />
  );
};

export default Disclaimer;