import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigation } from './NavigationContext';
import './DetailedPage.css';


const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DetailedPage = ({ onCheckoutClick, userName: initialUserName, userEmail: initialUserEmail, audioUrlObject, audioUrl, contactId: initialContactId }) => {
  const targetRef = useRef(null);
  const [loading, setLoading] = useState({ starter: false, mostPopular: false, bestValue: false });

  // Which plan was clicked? (null if none)
  const [buyClicked, setBuyClicked] = useState(null);

  // NEW state for our preview data
  const [previewChatHistory, setPreviewChatHistory] = useState(null);
  const [previewChatSummary, setPreviewChatSummary] = useState(null);
  const [previewAudioUrl, setPreviewAudioUrl] = useState(null);

  // Local states for userName, userEmail, contactId
  // We'll initialize them from props if provided, but they can be overwritten by the preview data
  const [localUserName, setLocalUserName] = useState(initialUserName || '');
  const [localUserEmail, setLocalUserEmail] = useState(initialUserEmail || '');
  const [localContactId, setLocalContactId] = useState(initialContactId || 0);

  const storedPreviewId = sessionStorage.getItem('preview_id');
  const [previewId, setPreviewId] = useState(storedPreviewId || '');

  // Access navigation context
  const { enableConfirmation } = useNavigation();

  useEffect(() => {
    // Extract the `previewId` from the URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const urlPreviewId = urlParams.get('previewId');

    if (urlPreviewId) {
      setPreviewId(urlPreviewId);
      sessionStorage.setItem('preview_id', urlPreviewId); // Save to sessionStorage for persistence
    }
  }, []);

  useEffect(() => {
    if (previewId) {
      fetchPreviewData(previewId);
    }
  }, [previewId]);

  // 2. Fetch data (JSON) from backend for this previewId
  const fetchPreviewData = async (previewId) => {
    try {
      const response = await axios.get(`${backendUrl}/api/preview/${previewId}`);
      // The response might look like: { chatHistory, chatSummary, previewAudioUrl, ... }
      setPreviewChatHistory(response.data.chatHistory);
      setPreviewChatSummary(response.data.chatSummary);

      if (response.data.userName) setLocalUserName(response.data.userName);
      if (response.data.userEmail) setLocalUserEmail(response.data.userEmail);
      if (response.data.contactId) setLocalContactId(response.data.contactId);

      // If the JSON has a S3 or backend URL for the preview audio, store it
      const remoteAudioUrl = response.data.previewAudioUrl;
      setPreviewAudioUrl(remoteAudioUrl);
    } catch (error) {
      console.error("Error fetching preview data:", error);
      // Handle error as needed
    }
  };

  // 3. If we have a remote previewAudioUrl but no audioUrlObject, fetch & replace it with a Blob URL
  useEffect(() => {
    if (previewAudioUrl && !audioUrlObject && !previewAudioUrl.startsWith('blob:')) {
      (async () => {
        try {
          const resp = await fetch(previewAudioUrl);
          const audioBlob = await resp.blob();
          const blobUrl = URL.createObjectURL(audioBlob);
          // Reuse previewAudioUrl variable to store the blob URL
          setPreviewAudioUrl(blobUrl);
        } catch (err) {
          console.error("Error converting preview audio to blob:", err);
        }
      })();
    }
    // Cleanup: if previewAudioUrl is a blob: URL, revoke on unmount
    return () => {
      if (previewAudioUrl && previewAudioUrl.startsWith('blob:')) {
        URL.revokeObjectURL(previewAudioUrl);
      }
    };
  }, [previewAudioUrl, audioUrlObject]);

  useEffect(() => {
    setLoading({
      starter: false,
      mostPopular: false,
      bestValue: false,
    });
    setBuyClicked(null);
    console.log('DetailedPage: Reset loading states on mount or previewId change.');
  }, [previewId]);

  useEffect(() => {
    const handlePageShow = (event) => {
      // Reset loading states and any other necessary states
      setLoading({
        starter: false,
        mostPopular: false,
        bestValue: false,
      });
      setBuyClicked(null);
      console.log('DetailedPage: Reset loading states on pageshow.');

      // Reactivate the confirmation popup
      enableConfirmation();
      console.log('DetailedPage: Reactivated confirmation popup.');
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, [enableConfirmation]);

  // Define the scrollToDiv function
  const scrollToDiv = () => {
    targetRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCheckout = async (priceOption) => {
    try {
      setBuyClicked(priceOption);
      setLoading((prev) => ({ ...prev, [priceOption]: true }));
      console.log(`Checkout clicked for: ${priceOption}`);
      console.log(`User Email: ${localUserEmail}, Contact ID: ${localContactId}, Preview ID: ${previewId}`);

      // Proceed with "Buy Now" logic
      onCheckoutClick(priceOption);

      const response = await axios.post(`${backendUrl}/api/checkout`, { price_option: priceOption, user_email: localUserEmail, contact_id: localContactId, user_name: localUserName, preview_id: previewId });
      const { checkout_url } = response.data;
      console.log(`Redirecting to Stripe checkout URL: ${checkout_url}`);

      setTimeout(() => {
        window.location.href = checkout_url; // Redirect to Stripe checkout
      }, 0);
    } catch (error) {
      console.error('Error during checkout:', error);
      setLoading((prev) => ({ ...prev, [priceOption]: false }));
    }
  };

  useEffect(() => {
    if (audioUrl) {
      // Create a URL object from the audio Blob
//      const url = URL.createObjectURL(audioUrl);
//      setAudioUrlObject(url);

      // Cleanup the object URL on unmount
      return () => URL.revokeObjectURL(audioUrlObject);
    }
  }, [audioUrl]);

  const finalAudioSrc = previewAudioUrl || audioUrlObject || audioUrl;

  return (
    <div className="detailed-page-container">
      <div className="audio-player-container">
        <div className="logo">
          <img src="ML_Logo_Hor_White.png" alt="ManifestLab Logo" className="logo-img" />
        </div>
        <h2>Your Personalized Manifestation Meditation Is Ready, {localUserName}!</h2>
        <div className="preview-instruction">
        <p>Click Play And Close Your Eyes to Enjoy a Free 2-Minute Preview</p>
        <p>(Headphones Recommended For Best Results):</p>
        </div>
          <div className="audio-container">
            {finalAudioSrc ? (
              <audio controls>
                <source src={finalAudioSrc} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            ) : (
              <p>Loading audio...</p>
            )}
          </div>
      </div>
      <div className="section-container pricing-heading" ref={targetRef}>
        <p>
          Unlock your full 20-minute meditation by purchasing the 'Starter' option below.
          <br></br>
          <br></br>
          Want more? Save with our 3 or 5 pack bundles and create meditations for different desires!
        </p>
      </div>
      <div className="section-container pricing-options">
        <div className="option starter">
          <div className="option-heading">
            <b>Starter</b><br></br>
          </div>
          <h2>1x</h2>
          <p>20 Minute Personalized Manifestation Meditation</p>
          <div className="option-price">
            <b>$19/meditation</b>
          </div>
          <div className="option-total-price">
            <p>($19 USD in total)</p>
          </div>
          <br></br><br></br>
          <button className="styled-button" onClick={() => handleCheckout('starter')}>
            Buy Now {loading.starter ? <span className="spinner"></span> : <span className="arrow">➔</span>}
          </button>
        </div>
        <div className="option most-popular">
          <div className="option-heading">
            <b>Most Popular</b>
          </div>
          <h2>3x</h2>
          <p>20 Minute Personalized Manifestation Meditations</p>
          <div className="option-price">
            <b>$10/meditation</b>
          </div>
          <div className="option-total-price">
            <p>($30 USD in total)</p>
          </div>
          <div className="discount">
          <b>Save 47%</b></div>
          <br></br>
          <button className="styled-button" onClick={() => handleCheckout('mostPopular')}>
            Buy Now {loading.mostPopular ? <span className="spinner"></span> : <span className="arrow">➔</span>}
          </button>
        </div>
        <div className="option best-value">
          <div className="option-heading">
            <b>Best Value</b>
          </div>
          <h2>5x</h2>
          <p>20 Minute Personalized Manifestation Meditations</p>
          <div className="option-price">
            <b>$8/meditation</b>
          </div>
          <div className="option-total-price">
            <p>($40 USD in total)</p>
          </div>
          <div className="discount">
          <b>Save 58%</b></div>
          <br></br>
          <button className="styled-button" onClick={() => handleCheckout('bestValue')}>
            Buy Now {loading.bestValue ? <span className="spinner"></span> : <span className="arrow">➔</span>}
          </button>
        </div>
      </div>
      <picture className="payment-icon-wrapper">
        <source media="(max-width: 768px)" srcSet="stripe_badge_mobile.png" />
        <img src="stripe_badge.png" alt="Stripe Badge" className="payment-icon" />
      </picture>

      <div className="section-container features-section">
        <h2>{localUserName}, Here’s What You’re Getting:</h2>
        <ul className="features">
          <li className="feature-item">
            <img src="feature-1.png" alt="Lifetime Access" className="feature-icon" />
          </li>
          <li className="feature-item">
            <div className="feature-text">
              <h3>Lifetime Access</h3>
              <p>Downloadable, high-quality audio files containing your full 20 minute personalized manifestation meditations. Play offline from any mobile, computer or device, anytime & anywhere. Each meditation is yours to keep forever.</p>
            </div>
          </li>
          <li className="feature-item">
            <img src="feature-4.png" alt="Personalized Affirmations" className="feature-icon" />
          </li>
          <li className="feature-item">
            <div className="feature-text">
              <h3>Personalized Affirmations</h3>
              <p>Your manifestation meditations contain over 100 bespoke affirmation messages carefully designed just for you. Play it daily and watch the magic start to happen!</p>
            </div>
          </li>
          <li className="feature-item">
            <img src="feature-2.png" alt="432Hz Audio Technology" className="feature-icon" />
          </li>
          <li className="feature-item">
            <div className="feature-text">
              <h3>432Hz Audio Technology</h3>
              <p>Your personalized meditation has been designed with background music tuned to 432Hz, known for fostering a serene and balanced mental state.</p>
            </div>
          </li>
          <li className="feature-item">
            <img src="feature-5.png" alt="528Hz Solfeggio Frequency" className="feature-icon" />
          </li>
          <li className="feature-item">
            <div className="feature-text">
              <h3>528Hz Solfeggio Frequency</h3>
              <p>Your meditation features the 528 Hz Solfeggio Frequency, often dubbed the ‘Miracle Tone’ and is renowned for fostering profound personal transformation.</p>
            </div>
          </li>
          <li className="feature-item">
            <img src="feature-3.png" alt="Theta Binaural Beats" className="feature-icon" />
          </li>
          <li className="feature-item">
            <div className="feature-text">
              <h3>Theta Binaural Beats</h3>
              <p>Theta waves have been incorporated to deepen your meditative state, enhancing relaxation and facilitating profound subconscious programming for more effective manifestation.</p>
            </div>
          </li>
          <li className="feature-item">
            <img src="feature-6.png" alt="Guided Relaxation" className="feature-icon" />
          </li>
          <li className="feature-item">
            <div className="feature-text">
              <h3>Guided Relaxation</h3>
              <p>Your complete personalized manifestation begins with a short guided visualisation technique to induce a state of calmness and heightened suggestibility, allowing your affirmations to be deeply accepted by your subconscious mind.</p>
            </div>
          </li>
        </ul>
      </div>

      <div className="section-container additional-info">
        <h2>Achieve More with Truly Personalized Manifestation</h2>
        <p>Imagine sitting down at a table and telling the waiter you’re hungry but giving no specifics about what you want. The likelihood of receiving a meal that satisfies you is slim.</p>
        <p>The universe works in a similar way when it comes to manifestation. It eagerly wants to fulfil your desires, but it needs clear, detailed instructions. Just as a chef requires a specific order to prepare the perfect dish, the universe needs precise, well-defined intentions to bring your dreams to life.</p>
        <p>Whether you are seeking to improve your health, make more money or attracting your soul mate - when you are vague or unsure about what you want, you often fail to see the changes you desire in your life. This is where the importance of specificity comes into play.</p>
        <p>At ManifestLab, our personalized meditations help you to clearly articulate your intentions, aligning your thoughts and emotions with your exact desires. By providing detailed instructions to the universe, you set a clear path for your dreams to become reality.</p>
        <p>Generic meditations, such as the ones you’ve probably been listening to on YouTube or other meditation apps, cannot achieve this level of resonance and specificity, which is why they often fail to produce significant results.</p>
        <p>Our custom-designed affirmations speak directly to your subconscious mind, helping to match your vibrational frequency with your heart’s deepest desires. This highly personalized approach amplifies your ability to attract what you want in your life, using key principles of the law of attraction.</p>
        <p>Each personalized manifestation meditation also uses future pacing techniques to help you vividly imagine and connect with your exact, desired future self. By experiencing what it feels like to already have achieved your specific goals, you activate a powerful magnetic force that draws your dreams into reality.</p>
        <p>At ManifestLab, we’re revolutionizing the manifestation experience. With our personalized affirmations, you’ll unlock a deeper connection to your true potential, transforming your life in ways you never imagined possible.</p>
        <button className="styled-button" id="features-button" onClick={scrollToDiv}>Get My Personalized Manifestation Meditation<span className="arrow">➔</span></button>
      </div>

      <div className="section-container faq-section">
        <h2>Frequently Asked Questions</h2>
        <ul className="faq">
          <li>
            <details>
              <summary><b>What will I be receiving?</b></summary>
              <p>You will be receiving a high quality, 20 minute fully personalized manifestation meditation audio file in MP3 format. After purchase, you will be emailed a link to download this audio file directly onto your computer or mobile phone.</p>
            </details>
          </li>
          <li>
            <details>
              <summary><b>Is there a subscription?</b></summary>
              <p>No, this is a once-off payment. Once you download your personalized manifestation meditation, it’s yours to keep forever.</p>
            </details>
          </li>
          <li>
            <details>
              <summary><b>How do I play the audio file?</b></summary>
              <p>You will be able to play your meditation through any music player app that supports MP3 files, such as the ‘Music’ app on a Mac or directly from your phone’s default media player.</p>
            </details>
          </li>
          <li>
            <details>
              <summary><b>How many times can I re-download the file?</b></summary>
              <p>You will be able to re-download your personalized manifestation meditation file as many times as you want within 21 days of your purchase. After this time, your file will be deleted from our server - so make sure to save a few copies on multiple devices so you don’t lose it!</p>
            </details>
          </li>
          <li>
            <details>
              <summary><b>Do you have an iOS or Android mobile app available?</b></summary>
              <p>Not yet, however we are actively working on rolling this out!</p>
            </details>
          </li>
          <li>
            <details>
              <summary><b>Do you offer refunds?</b></summary>
              <p>Since this is a digital download, we're not able to offer refunds.</p>
            </details>
          </li>
          <li>
            <details>
              <summary><b>How often should I play these meditations?</b></summary>
              <p>As with all forms of manifestation practices, the more frequently you listen to your personalized meditations, the greater the likelihood of achieving the desired changes in your life.</p>
            </details>
          </li>
          <li>
            <details>
              <summary><b>When should I play these meditations? Can I play them whilst driving or doing other activities?</b></summary>
              <p>As your personalized manifestation meditations have been recorded with Theta waves and guided relaxation techniques, it is not recommended to listen to these whilst performing other activities such as driving. It is best to treat these as you would other meditation sessions and use them when you’re wanting to enter a state of deep relaxation or to reduce stress. First thing in the morning, during your lunch break or immediately before sleep are great times to listen.</p>
            </details>
          </li>
          <li>
            <p>Have any more questions? Please feel free to reach out to us at <a href="mailto:contact@manifestlab.io">contact@manifestlab.io</a>.</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DetailedPage;